import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  useMediaQuery, Paper, Hidden, Grid
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import toast from 'react-hot-toast';
import { useMutation } from '@apollo/client';
import currencyFormatter from '../../shared/currencyFormatter';
import {
  BodyCell, MainTableRow, ItemGrid, ItemContent, ItemLogo, ItemTypo, VisibleImg, TrackOrderButton,
  DetailContainer, TypeImg, DetailButton, OpenButton, DetailContent, ImgContent, ColorGrid, DetailsContent, ActionItem, Dot, CardLogo, Dots
} from './returnRow.styles';
import SavingsLogo from '../../../assets/images/marketOrder/savings.png';
import TotalLogo from '../../../assets/images/marketOrder/newTotal.png';
import DeliveryLogo from '../../../assets/images/marketOrder/deliveryDate.png';
import VisibilityLogo from '../../../assets/images/marketOrder/visibility.png';
import TrackOrderLogo from '../../../assets/images/marketOrder/whiteLocationTrack.png';
import { Logo, VerticalDots } from '../../../assets/svgs';
import ActionPopper from './actionPopper';
import { AFFILIATE_REORDER, CREATE_ORDER_MUTATION } from '../../../mutations/orders';
import CartActionTypes from '../../../providers/reducers/cart/cartTypes';
import { useStateValue } from '../../../providers/stateProvider';

const ReturnRow = ({ orderItem, index }) => {
  const navigate = useNavigate();

  const isLarge = useMediaQuery('(min-width:992px)');

  const {
    id, total, business, status, savings, datePlaced: date
  } = orderItem;
  const { logo } = business;
  const [action, setAction] = useState(null);

  const [productReorder] = useMutation(AFFILIATE_REORDER);
  const [, dispatch] = Object.values(useStateValue());

  const dateFormat = 'l h:mm';
  // const dateFormat = isLarge ? 'MMMM Do YY h:mm a' : 'l';
  const datePlaced = moment(date).format(dateFormat);
  const amount = currencyFormatter(total);
  const deliveryDate = moment(datePlaced).add(3, 'days').format('DD/MM/YY');

  const getColorGrid = (color, bkg, name) => (
    <ColorGrid item container className={`mpAdmin-uat-ordersPage-${name}`}>
      <Dot background={bkg} />
      {name}
    </ColorGrid>
  );
  const getStatus = () => {
    switch (status) {
      case 'ORDER_SENT':
        return getColorGrid('#606060', '#F29053', 'Open Orders');
      case 'ORDER_RECEIVED':
        return getColorGrid('#07DE10', '#00B588', 'Confirmed Orders');
      case 'ORDER_DISPATCHED':
        return getColorGrid('#606060', '#F29053', 'Dispatched Orders');
      case 'ORDER_CLOSED':
        return getColorGrid('#606060', '#F29053', 'Closed Orders');
      case 'ORDER_CANCELED':
        return getColorGrid('#606060', '#FF0F0F', 'Canceled');
      default:
        return getColorGrid('#606060', '#F29053', 'N/A');
    }
  };
  const handleClick = (_id) => {
    if (!isLarge) return;
    navigate(`/orders/${_id}/affiliate`);
  };

  const handleViewDetails = (_id) => {
    navigate(`/orders/${_id}/affiliate`);
  };

  const handleTrackOrder = (_id) => {
    navigate('/track-order', {
      state: { orderId: _id }
    });
  };

  const handleOptions = (event) => {
    setAction(action ? null : event.currentTarget);
  };

  const updateCartTotal = (currentOrder) => {
    const { totalProductCount } = currentOrder || {};

    dispatch({
      type: CartActionTypes.CART_COUNT,
      payload: totalProductCount
    });
  };
  const [createOrder] = useMutation(CREATE_ORDER_MUTATION, {
    onCompleted(data) {
      updateCartTotal(data?.createOrder);
    }
  });

  const handleReorder = () => {
    productReorder({
      variables: {
        oldOrderId: id,
      }
    }).then(({ data }) => {
      const { message } = data?.productReorder ?? '';
      toast.success(message);
      createOrder();
    }).catch((err) => {
      toast.error(err?.message);
    });
  };

  const handleAction = (event, type) => {
    if (type === 'Details') {
      handleViewDetails(id);
    } else if (type === 'Re-order') {
      return handleReorder();
    } else {
      handleTrackOrder(id);
    }
    handleOptions(event);
  };

  const handleCloseModal = () => {
    setAction(false);
  };
  return (
    <ItemGrid container component={Paper} className={`mpFe-uat-orders-cards-${index}`}>
      <ItemContent item xs={12} md={2} onClick={() => handleViewDetails(id)}>
        <ImgContent>
          {/* <ItemLogo src={logo} alt="logo" /> */}
          <CardLogo />
        </ImgContent>
      </ItemContent>
      <ItemContent item xs={12} md={6} onClick={() => handleClick(id)}>
        <Hidden mdUp>
          <Grid container xs={12}>
            <Grid xs={11}>
              <DetailContent>
                { getStatus() }
              </DetailContent>
            </Grid>
            <Grid xs={1}><Dots fontSize="7rem" onClick={handleOptions} /></Grid>
          </Grid>
        </Hidden>
        <ItemTypo>
          Placed on &nbsp;
          {datePlaced}
        </ItemTypo>
        <ItemTypo>
          #
          {id}
        </ItemTypo>
        <DetailContainer>
          <TypeImg src={DeliveryLogo} alt="delivery date" />
          &nbsp; &nbsp;
          <ItemTypo>
            Estimated Delivery Date: &nbsp;
            {deliveryDate}
          </ItemTypo>
        </DetailContainer>
        <DetailContainer>
          <TypeImg src={TotalLogo} alt="total" />
          &nbsp; &nbsp;
          <ItemTypo>
            New Total: &nbsp;
            <Hidden mdUp>
              <b>{`₦${amount}`}</b>
            </Hidden>
            <Hidden mdDown>
              {`₦${amount}`}
            </Hidden>
          </ItemTypo>
          &nbsp; &nbsp;
          <TypeImg src={SavingsLogo} alt="savings" />
          &nbsp; &nbsp;
          <ItemTypo>
            Savings: &nbsp;
            <Hidden mdDown>{`₦${savings}`}</Hidden>
            <Hidden mdUp><b>{`₦${savings}`}</b></Hidden>
          </ItemTypo>
        </DetailContainer>
      </ItemContent>
      <ItemContent item xs={12} md={4}>
        <ActionItem container>
          <DetailsContent>
            <Hidden mdDown><VerticalDots fontSize="large" onClick={handleOptions} /></Hidden>
            <ActionPopper
              action={action}
              handleAction={handleAction}
              handleClose={handleCloseModal}
            />
          </DetailsContent>
          <Hidden mdDown>
            <DetailContent onClick={() => handleClick(id)}>
              { getStatus() }
            </DetailContent>
          </Hidden>
        </ActionItem>
        {/* <DetailsContent container spacing={3}>
          <Grid item sm={12} md={6}>
            <DetailButton
              size="medium"
              variant="outlined"
              startIcon={<VisibleImg src={VisibilityLogo} alt="visible" />}
              onClick={() => handleClick(id)}
            >
              View Order Details
            </DetailButton>
          </Grid>
          <Grid item sm={12} md={6}>
            <TrackOrderButton
              size="medium"
              variant="outlined"
              startIcon={<VisibleImg src={TrackOrderLogo} alt="visible" />}
              onClick={() => handleTrackOrder(id)}
            >
              Track Order
            </TrackOrderButton>
          </Grid>
        </DetailsContent> */}
      </ItemContent>
    </ItemGrid>
    // <MainTableRow>
    //   {renderBodyCells()}
    // </MainTableRow>
  );
};

ReturnRow.propTypes = {
  orderItem: PropTypes.shape(Object),
};

ReturnRow.defaultProps = {
  orderItem: {},
};

export default ReturnRow;
