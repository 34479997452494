import {
  Grid, Typography, IconButton, Box, Paper, TextField, Checkbox, Button, Dialog,
  CircularProgress, DialogContentText, Table, TableHead, TableRow, TableCell, TableContainer
} from '@mui/material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TelegramIcon from '@mui/icons-material/Telegram';
import CachedIcon from '@mui/icons-material/Cached';
import { styled } from '@mui/material/styles';
import { CartDot, Plus } from '../../assets/svgs';

// the width in pixels for sm breakpoint.
const sm = '960px';

export const BackRouteText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const CustomToast = styled('div')`
  color: #ffffff;
  background: #3676de;
  padding: 5px;
`;

export const MenuButtonStyled = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const NavbarContainer = styled(Grid)`
  height: 100px;
  background-color: #424242;
  padding-left: 35px;
  box-sizing: border-box;
`;

export const BackText = styled(Typography)`
  font-size: 1.2rem;
  font-weight: 500;
  color: #303030;

  @media(min-width: 992px) {
    font-size: 1rem;
  }
`;

export const GoBack = styled(Grid)`
  margin: 1.5rem 0 0 0;
`;

export const SeperatorGrid = styled(Grid)`
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  color: white;
  box-sizing: border-box;
`;

export const NavLeftMenu = styled(Grid)`
  justify-content: flex-start;
  align-items: center;
`;

export const NavRightMenu = styled(Grid)`
  align-items: center;
  justify-content: flex-end;
`;

export const MenuButton = styled(IconButton)`
  color: #424242;
  padding: 2px;
`;

export const HeaderMenuButton = styled(IconButton)`
  color: white;
  margin-right: 50px;
`;

export const NavItem = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 91px;
  text-align: right;
  border-left: 1px solid rgba(240, 240, 240, .1);
  height: 100px;
  justify-content: center;
  align-items: center;
  border-radius:0px;
`;

export const DatetimeTypo = styled(Typography)`
  font-size: 14px;
  color: #A3A3A3;
  letter-spacing: 0px;
  text-align: right;
  padding: 0px 20px;
`;

export const AffiliatesLogo = styled(Box)`
    display: flex;
    justify-content: center;
    align-items:  center;
    width: 119px;
    height: 100px;
    border-left: 1px solid rgba(240, 240, 240, .1);
    border-radius:0px;
    @media(max-width: ${sm}) {
      border-left: 0px;
    }
`;

export const PharmIQImage = styled('img')`
    height: 50px;
    justify-self: flex-start;
`;

export const HeaderIcon = styled('img')`
    height: 25px;
`;

export const AffiliateImage = styled('img')`
  height: 50px;
  width: 50px;
`;

export const ContentWrapper = styled(Grid)`
  display:flex;
  flex-wrap: nowrap;
  background: #424242;
`;

export const SideNav = styled(Grid)`
  color: white;
  background: #424242;
  top: -100px;
  width: 400px;
  min-height: calc(100vh + 100px);
  position: relative;
  overflow: hidden;
  transition: 400ms ease-in-out;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MainContent = styled(Grid)`
//   height: calc(100vh + 100px);
  background: #F0F0F0;
`;

export const SideNavHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height:100px;
  width: 400px;
  border-bottom: 1px solid rgba(240, 240, 240, .1);
`;

export const SideNavItems = styled(Grid)`
  margin-top:50px;
  width: 100%;
`;

export const SideNavItem = styled(IconButton)`
  border-radius: 0px;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 45px;
  width: 100%;
  justify-content: flex-start;
  color: #CCCCCC;
`;

export const SideNavText = styled(Typography)`
    margin-left: 50px;
    font-size: 16px;
    height: 19px;
    font-weight: 600;
    text-transform: capitalize;
`;

export const FullPharmIQLogo = styled('img')`
  width: 196px;
  height: 50px;
  margin-left: 35px;
`;

export const NavigationIcon = styled(IconButton)`
    height: 50px;
`;

export const MobileNavArrow = styled('img')`
  width: 9px;
  height: 8px;
`;

export const SideNavBackground = styled('img')`
  position:absolute;
  // height: 100%;
  // width: 100%;
  z-index: 12;
  margin-top: 100px;
`;

export const CartGridContainer = styled(Grid)`
  flex-direction: column;
  padding: 10px 2rem;
  width: 100%;
  background-color: #FFFFFF;
  margin-bottom: 25rem;
  @media (min-width: 992px) {
    padding: 1px 4rem 3rem 4rem;
    margin-bottom: 0rem;
  }
`;

export const TitleGridContainer = styled(Grid)`
  justify-content: space-between;
  align-items: flex-end;
`;

export const TitleTextGridContainer = styled(Grid)`
  flex-direction: column;
`;

export const PimaryTitle = styled(Typography)`
  color: #424242;
  font-size: 30px;
`;

export const SecondaryTitle = styled(Typography)`
  font-size: 16px;
  color: #424242;
`;

export const UpdateButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 15px;
  // background-color: #fff;
  color: #235A91;
  border-radius: 15px;
  border: 1px solid #235A91;
  height: 70px;
  &:hover {
    background-color: #ffff;
  }
  &:disabled {
    border: 3px solid #b2b2b2;
  }
  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1.5px solid #424242;
    &:disabled {
      border: 1.5px solid #b2b2b2;
    }
  }
`;

export const ToggleButton = styled(IconButton)`
  display: flex;
  height: 70px;
  justify-content: space-between;
  align-items: center;
  padding: 17px 35px;
  color: #424242;
  border-radius: 15px;
  border: 3px solid #424242;
  &:disabled {
    border: 3px solid #b2b2b2;
  }
  @media(min-width: 992px) {
    height: 50px;
    padding: 0px 25px;
    border-radius: 10px;
    border: 1.5px solid #424242;
    &:disabled {
      border: 1.5px solid #b2b2b2;
    }
  }
`;

export const OrderButton = styled(IconButton)`
  display: flex;
  width: 18rem;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  background-color: #235A91;
  color: #ffffff;
  border-radius: 0;
  border-radius: 10px;
  height: 70px;
  &:hover {
    background-color: #235A91;
  }
  &:disabled {
    color: #FFFFFF;
    background-color: #235A91;
    opacity: 0.6;
  }
  @media(min-width: 992px) {
    height: 50px;
    width: 14rem;
    padding: 0px 25px;
    border-radius: 10px;
  }
`;

export const PlaceOrderIcon = styled(TelegramIcon)`
  width: 4rem;
  height: 4rem;
  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const UpdateCartIcon = styled(CachedIcon)`
  width: 4rem;
  height: 4rem;
  @media(min-width: 992px) {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const OrderButtonText = styled(Typography)`
  font-size: 28px;
  font-weight: 500;
  @media (min-width: 992px) {
    font-size: 16px;
  }
`;

export const DialogActionButtonText = styled('p')`
  font-size: 3rem;
  margin: 0;
  @media (min-width: 992px) {
    font-size: 14px;
  }
`;

export const SupplierProductsCardHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-bottom: 2px solid #efefef;
  @media (min-width: 992px) {
    padding: 15px 2.3rem;
    border-bottom: 1px solid #e8e8e8;
  }
`;

export const SupplierProductsCardHeaderText = styled('p')`
  color: #a3a3a3;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
  @media (min-width: 992px) {
    font-size: 20px;
  }
`;
export const SupplierProductsCardFooter = styled(Grid)`
  justify-content: space-between;
  align-items: center;
  padding: 10px 35px;
  @media (min-width: 992px) {
    padding: 5px 2.3rem;
  }
`;

export const SupplierProductsCardFooterText = styled(Typography)`
  font-size: 30px;
  font-weight: 500;
  margin-left: .5rem;
  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const FieldCheckbox = styled(Checkbox)`
  color: #235A91;
  padding: 6px;
`;

export const CheckboxImage = styled('img')`
  width: 2rem;
  @media (min-width: 992px) {
    width: 17px;
  }
`;

export const ProductSubTotalHeaders = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 35px;
  border-top: 1px solid #E7E8E9;
  width: 48%;
  margin-right: 14px;
  @media (max-width: 991px) {
    padding: 3rem;
    font-size: 2rem;
    width: 100%;
    justify-content: space-around;
    border-top: none;
  }
`;

export const ProductSubTotalWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
`;

export const ProductColumnHeaderTitle = styled('span')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  @media (min-width: 992px) {
    font-size: 17px;
    color: #606060;
    font-weight: 700;
  }
  @media (max-width: 991px) {
    font-size: 2.5rem;
  }
`;

export const ProductColumnHeaderTitleW = styled('span')`
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  white-space: nowrap;
  min-width: 410px;
  max-width: 410px;
`;

export const ProductSubTotalHeadersMobile = styled('div')`
  display: none;
  @media (max-width: 1199px) {
    display: flex;
    padding: 2rem 2rem 2rem 31.5rem;
    justify-content: flex-start;
    color: #424242;
    font-size: 1.5rem;
    font-weight: 600;
    & > * {
      margin: 0;
      &:last-child {
        margin-left: 3.5rem;
      }
    }
  }
  @media (max-width: 991px) {
    padding: 2rem;
    font-size: 2rem;
    justify-content: space-between;
  }
`;

export const BackArrowIconContainer = styled(IconButton)`
  padding: 10px 0px;
  margin: 0;
  background: none;
  color: #235A91;
  font-size: 14px;
  @media(max-width: 991px) {
    padding: 15px 0px;
  }
`;

export const KeyboardArrowLeft = styled(KeyboardBackspaceIcon)`
  font-size: 3rem;
  @media(min-width: 992px) {
    font-size: 1.8rem;
  }
`;
export const OutletInfoContainer = styled(Grid)`
  padding: .5rem .7rem;
  background: #F5FAFF;
  border-radius: 8px;
  align-items: center;
  @media (max-width: 991px) {
    display: none;
  }
`;

export const OutletContactHeader = styled(Grid)`
  font-size: .9rem;
  color: #000000;
  font-weight: 500;
  @media(max-width: 991px) {
    font-weight: 600;
    font-size: 2rem;
  }
`;

export const OutletContactLine = styled(Typography)`
  font-size: .8rem;
  color: #606060;
  font-weight: 600;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const OutletHeader = styled(Typography)`
  font-size: 1rem;
  color: #606060;
  font-weight: 500;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const PageTitleText = styled('p')`
  color: #303030;
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0;
  @media (min-width: 992px) {
    font-size: 25px;
    padding-top: 0;
  }
`;

export const ArrowBackText = styled('p')`
  color: #235A91;
  font-size: 4rem;
  margin: 0;
  padding-top: 1rem;
  @media (min-width: 992px) {
    font-size: 16px;
    padding-top: 0;
  }
  @media (max-width: 991px) {
    font-weight: 400;
    font-size: 2.2rem;
    padding-top: 0;
    padding-left: 10px;
    color: #000000;
  }
`;

export const PageTitleSubText = styled('p')`
  color: #606060;
  margin: 0;
  font-size: 2.1rem;
  font-weight: 500;
  padding-bottom: 1.5rem;

  @media (min-width: 992px) {
    font-size: 16px;
    margin-top: 5px;
  }
`;

export const CartItemCountContainer = styled('div')`
  display: inline;
  width: 95%;
  justify-content: space-between;
  padding: 10px 0;
  @media (min-width: 992px) {
    padding-top: 0;
    padding-bottom: 25px;
    padding-right: 45px;
    padding-left: 5px;
    width: 70%;
    display: flex;
    position: relative;
  }

  @media (max-width: 991px) {
    position: fixed;
    background-color: #fff;
    z-index: 100000;

  }
`;
export const CartItemContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media (min-width: 992px) {
    display: flex;
  }
`;
export const CartContainer = styled(Grid)`
  @media(max-width: 991px) {
    margin-top: 30rem;

  }

`;

export const CartItemCountText = styled(Typography)`
  color: #606060;
  font-size: 15px;
  margin-left: 15px;
  font-weight: 600;
  opacity: 50%;

  @media(max-width: 991px) {
    font-weight: 500;
    margin-left: 25px;
    font-size: 35px;
  }
`;
export const CartItemWrapper = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @media (max-width: 991px) {
    margin-top: 1rem;
    justify-content: flex-start;
  }
`;

export const LoaderGrid = styled(Grid)`
  justify-content: center;
  align-items: center;
  min-height: 445px;
`;

export const ClearAllCart = styled(Button)`
  background-color: #235A91;
  padding: 20px 1rem;
  color: #FFFFFF;
  font-size: 32px;
  font-weight: 600;
  border-radius: 20px;
  width: 100%;
  &:hover {
    color: #FFFFFF;
    background-color: #235A91;
  }
  @media (min-width: 992px) {
    font-size: 14px;
    padding: 10px 2rem;
    border-radius: 5px;
    border: none;
    margin-left: 1rem;

  }
`;

export const ProductColumnHeaders = styled('div')`
  display: flex;
  width: 100%;
  margin-top: 20px;
`;

export const NewProductColumnHeaders = styled('div')`
  grid-template-columns: 38% 13% 20% 20% 0%;
  display: grid;
  padding: 28px 35px;
  margin-top: 30px;
`;
export const ColumnHeaderGrid = styled(Grid)`
  padding: 20px 10px;
  margin-top: 30px;
`;

export const DeliveryAddressCard = styled(Grid)`
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  border-radius: 15px;
  width: 100%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 2px;
    margin-top: 2rem;
    border-radius: 8px;
  }
`;

export const DeliveryAddressCard2 = styled(Grid)`
  // box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  // border-radius: 8px;
  @media (max-width: 991px) {
    width: 100%;
    padding-top: 3rem;
    border-radius: 15px;
  }
`;

export const Span = styled('span')`
`;

export const DeliveryAddressCardSection = styled(Paper)`
  padding: 0 30px 30px 30px;
  min-height: 455px;
  @media (min-width: 992px) {
    box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
    border-radius: 12px;
    padding: 3rem;
  }
  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
    border-radius: 15px;
    min-height: 560px;
    box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;

  }
`;

export const PaymentCardSection = styled(Paper)`
  padding: 0 30px 30px 30px;
  min-height: 455px;
  margin: 1.2rem 1.2rem 0 1.2rem;
  box-shadow: 0px 2.96px 66.64px rgba(163, 171, 185, 0.12);
  border-radius: 12px;
  @media (max-width: 991px) {
    padding: 0 2rem 3rem;
    width: 100%;
    border-radius: 15px;
    min-height: 560px;
    margin: 0rem;
  }
`;

export const DeliveryAddressCardHeader = styled('div')`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 10px;

  @media (min-width: 992px) {
    padding-top: 17px;
    padding-bottom: 5px;
  }
`;

export const DeliveryAddressCardHeaderTitle = styled('p')`
  margin: 0;
  color: #303030;
  font-weight: 700;
  font-size: 2.2rem;
  line-height: 164.2%;
  @media (min-width: 992px) {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const SupplierCardMainWrapper = styled('div')`
  margin-top: 20px;
  margin-bottom: 52px;
`;

export const DeliveryAddressCardHeaderSubTitle = styled(Button)`
  margin: 0;
  color: #235A91;
  background: none;
  font-size: 2rem;
  line-height: 32px;
  outline: none;
  font-family: Montserrat,medium,sans-serif;
  padding: 2rem 0rem;
  font-weight: 500;
  &:hover {
    color: #FFFFFF;
    background: ##235A91;
  }
  @media (min-width: 992px) {
    font-size: 12px;
    padding: 5px 10px;
  font-weight: 700;

  }

  @media(max-width: 991px) {
    width: 100%;
    margin-bottom: 35px;
    justify-content: flex-start;
    text-transform: none;
  }
`;

export const DeliveryAddressCardLocation = styled('p')`
  font-size: 2rem;
  line-height: 40px;
  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const PaymentSummaryRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const PaymentSummaryRowTitle = styled('p')`
  color: #424242;
  font-size: 2rem;
  line-height: 32px;
  margin: 0;
  &:nth-child(2n) {
    margin-left: 6rem;
  }
  @media (min-width: 992px) {
    font-size: 20px
  }
`;

export const PaymentSummaryRowBoldTitle = styled('p')`
  color: #424242;
  font-size: 2rem;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
  &:nth-child(2n) {
    margin-left: 6rem;
  }
  @media (min-width: 992px) {
    font-size: 20px;
  }
`;

export const PaymentSummaryGrandTotalRow = styled('div')`
  display: flex;
  align-items: center;
  border-top: 1px solid #E8E8E8;
  padding-top: 20px;
  margin-top: 20px;
  @media (max-width: 991px) {
    padding-top: 35px;
    border-top: 2px solid #ECECEC;
  }
`;

export const SupplierCardPaper = styled(Paper)`
  margin-top: 7rem;
  margin-bottom: 52px;
  padding-bottom: 30px;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
  padding-top: 20px;
  @media (min-width: 992px) {
    margin-top: 20px;
      box-shadow: 0px 2.96px 66.64px 0px #A3ABB91F;
    border-radius: 12px;
  }
`;

export const SupplierCard = styled(Grid)`
  width: 100%;

`;

export const CustomTBody = styled(Box)`
  overflow: hidden;
  overflow-y: scroll;
  max-height: 300px;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 10px;
  }
`;

export const EmptyCardPaper = styled(Paper)`
  margin-top: 45px;
  margin-bottom: 52px;
  border-radius: 15px;
  min-height: 470px;
  width: 100%;
  @media (min-width: 992px) {
    margin-top: 20px;
    border-radius: 4px;
    min-height: 455px;
  }
`;

export const CartBottomActionsButtonContainer = styled('div')`
  margin: 50px 0;
  display: flex;
  justify-content: flex-end;
  @media(max-width: 991px) {
    justify-content: center;
  }
`;

export const PageTitleContainer = styled('div')`
  margin-right: auto;
`;

export const CartGridContainerHeader = styled(Grid)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;

  @media (min-width: 992px) {
    margin-bottom: 0;
    margin-top: 1rem;
  }
`;

export const DialogCancelButton = styled(IconButton)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 45px;
  color: #235A91;
  border-radius: 0;
  border-radius: 10px;
  margin-right: 7px;
  border: 1px solid #235A91;
`;

export const ManualDialogDoneButton = styled(IconButton)`
  height: 40px;
  min-width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 25px;
  border-radius: 5px;
  margin-right: 25px;
  background-color: #FCED50!important;
  color: #424242;
  &:hover {
    background-color: #D2C647;
  }
`;
export const ManualDialogCancelButton = styled(ManualDialogDoneButton)`
    background-color: #fff!important;
    color: #424242;
    border: 1px solid grey;
`;

export const ManualDialogPadder = styled(Grid)`
    padding: 30px 80px;
`;

export const ManualPlaceOrder = styled('h2')`
    font-weight: 600;
`;

export const DialogOkButton = styled(IconButton)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 5px;
  border-radius: 5px;
  margin-right: 7px;
  background-color: #235A91;
  color: #ffffff;
  min-width: 200px;
  border: 1px solid #235A91;
  &:hover {
    background-color: #235A91;
    color: #fff;
  }
`;

export const ChangeLocationButton = styled('button')`
  background-color: white;
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #424242;
  outline: none;
  &:not(:last-of-type) {
    margin-right: 50px;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const ChangeLocationActions = styled('div')`
`;

export const ChangeLocationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 60%;
`;

export const ChangeLocationLowRow = styled('div')`
  display: flex;
  & > * {
    margin-right: 15px;
  }
`;
export const ChangeOutletRow = styled(Grid)`
  margin-top: 1rem;
  & > * {
    font-size: 2rem;
    @media(min-width: 992px) {
      font-size: 0.875rem;
    }
  }
`;
export const SelectOutletText = styled('p')`
  font-size: 15px;
  color: #606060;
  font-weight: 500;
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;
export const LocationTextField = styled(TextField)`
  background-color: #fff;
  height: 55px;
  label + .MuiInput-formControl {
    margin-top: 21px;
  }
  label {
    left: 20px;
    top: 5px;
    color: #A3A3A3;
  }
  input, textarea {
    padding-left: 20px;
    background-color: #fff;
    color: #424242;
    font-size: 16px;
    margin-top: 5px;
  }
  @media (max-width: 991px) {
    height: 80px;
    label + .MuiInput-formControl {
      margin-top: 23px;
    }
    label {
      font-size: 1.6rem;
    }
    input, textarea {
      font-size: 2.5rem;
    }
  }
`;
export const ManualMethodImage = styled('img')`
  width: 6rem;
  display: block;
  margin-bottom: 30px;
  @media(min-width: 992px) {
    width: 4rem;
  }
`;
export const CartImage = styled('img')`
  width: 3.5rem;
  display: block;
  @media(min-width: 992px) {
    width: 2.5rem;
  }
`;
export const ManualSupplierItem = styled(Grid)`
  margin-right: 20px;
`;
export const OptionText = styled(Grid)`
  margin-bottom: 15px;
  color: #424242;
  font-weight: 500;
`;

export const CircularProgressLoader = styled(CircularProgress)`
  color: #235A91;
  margin-top: 4px;
  .MuiCircularProgress-circle {
    stroke:  #235A91;
  },
  &.MuiCircularProgress-circleDeterminate {
    stroke:  #ECF6FF;
  },
`;

export const TitleText = styled(Typography)`
  font-size: 2.25rem;
  font-weight: 500;
  @media(min-width: 992px) {
    font-size: 1.25rem;
  }
`;

export const ContentText = styled(DialogContentText)`
  @media(max-width: 991px) {
    font-size: 2rem;
  }
`;

export const TableComponent = styled(Table)`
  margin-bottom: 1rem;
`;

export const TableHeader = styled(TableHead)`
  display: table-header-group;
  background-color: #F5FAFF;
  border: none;
  @media (max-width: 991px) {
    display: none;
  }
`;
export const MainTableRow = styled(TableRow)`
  border-bottom: 1px solid #E7E8E9;
  & > * {
    border-bottom: unset;
  }
    @media (max-width: 991px) {
    display: none;
  }
`;

export const Header = styled(TableCell)`
  padding: 20px 5px;
  font-weight: 600;
  font-size: 1rem;
  :first-child {
    width: 40%;
  }
  :last-child {
    width: 10%;
  }
  @media(min-width: 992px) {
    font-size: 1rem;
    font-weight: 700;
    :first-child {
      width: 40%;
      padding: .7rem;
    }
    &:nth-child(2) {
      width: 15%;
    }
    &:nth-child(3) {
      width: 25%;
    }
  }
`;

export const ViewMore = styled(Typography)`
transform: rotate(90deg);
font-weight: 700;
font-size: 1rem;
text-align: center;
&:hover {
  cursor: pointer;
}
@media(max-width: 991px) {
  font-size: 2rem;
}
`;

export const GridContainer = styled(Grid)`
    padding: 5px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;
    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
`;

export const EditContainer = styled(Grid)`
    padding: 5px 25px;
    width: 25rem;
    align-items: center;
    cursor: pointer;
    background: #F4F5F6;
    @media(min-width: 992px) {
      padding: 0px;
      width: 231px
    }
`;

export const GridWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
    border-radius: 10px;
`;

export const ItemTypo = styled(Typography)`
  margin-left: 25px;
  font-size: 2rem;
  @media(min-width: 992px) {
    margin-left: 0px;
    font-size: 1.2rem;
    padding: 6px 12px;
  }
`;

export const ItemWrapper = styled(IconButton)`
    border-radius: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
`;

export const TableWrapper = styled(TableContainer)`
  padding: 15px;

  @media (min-width: 992px) {
    padding: 10px 30px 30px;
  }
`;

export const TextWrapper = styled(Grid)`
  justify-content: center;
  align-items: center;
  width: 50%;
  text-align: center;
  margin: 0 2rem 1rem 14rem;
  padding-bottom: 2rem;
  @media(max-width: 991px) {
    width: 90%;
    padding-bottom: 4rem;
    margin: 0 2rem 1rem 2rem;
  }
`;

export const TextHeader = styled(Typography)`
  font-size: 1.2rem;
  color: #303030;
  padding-bottom: 25px;
  font-weight: 600;
  @media(max-width: 991px) {
    font-size: 2.5rem;
    font-weight: 700;
  }
`;

export const TextBody = styled(Typography)`
  font-size: 1rem;
  color: #606060;
  padding-bottom: 10px;
  @media(max-width: 991px) {
    font-size: 2.1rem;
  }
`;

export const IconWrapper = styled(IconButton)`
  position: relative;
  @media(max-width: 991px) {
    &.MuiSvgIcon-fontSizeLarge {
      font-size: 6rem;
    }
  }
  @media(min-width: 992px) {
    background-color: #F4F5F6;
    height: 40px;
    width: 40px;
    margin-top: .5rem;
    margin-left: 11rem;
    & .MuiSvgIcon-root {
      padding: 3px;
      color: #000000;
      width: 100%;
      height: 100%;
    }
  }
`;

export const CDialog = styled(Dialog)`
  @media (min-width: 992px) {
    & .MuiDialog-paperScrollPaper {
      border-radius: 12px;
      min-width: 35rem;
    }
  }
  @media (max-width: 991px) {
    & .MuiDialog-paperScrollPaper {
      height: 45%;
      border-radius: 25px;
      width: 100%;
    }
  }
`;

export const ClearBox = styled(Grid)`
  @media (max-width: 991px) {
  align-items: center;
  }
`;

export const ScrollBox = styled(Box)`
  max-height: 500px;
  overflow: hidden;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #DDDDDD;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #303030;
    border-radius: 10px;
  }
`;

export const EmptyCartImg = styled('img')`
  display: block;
  @media (min-width: 992px) {
    width: 10rem;
    height: 10rem;
  }
  @media (max-width: 991px) {
    width: 15rem;
    height: 15rem;
  }
`;

export const WarningImg = styled('img')`
  @media (min-width: 992px) {
    width: 200px;
  }
  @media (max-width: 991px) {
    width: 20rem;
    margin-top: 10rem;
  }
`;

export const DefaultText = styled(Typography)`
background: #DCEDFF;
padding: 8px 10px;
font-weight: 600;
font-size: 13px;
color: #235A91;
display: flex;
justify-content: center;
align-items: center;
border-radius: 7px;

`;

export const DeliveryIcon = styled(Plus)`
&.MuiSvgIcon-root {
  width: 10%;
  margin-right: 7px;
}
@media (max-width: 991px) {
  &.MuiSvgIcon-root {
    width: 5%;
    height: 4%;
    margin-right: 15px;
  }
  }
`;

export const Default = styled(Typography)`
  color: #235A91;
  background: #DCEDFF;
  border-radius: 7px;
  padding: 10px;
  font-weight: 600;
  font-size: 13px;

  @media (max-width: 991px) {
  font-size: 18px;

  }
`;

export const SearchGrid = styled(Grid)`
  width: 100%;
  padding: 20px 0px;

  @media (min-width: 992px) {
    padding: 30px 30px 8px;
  }
`;

export const FooterGrid = styled(Grid)`
  align-items: center;
  margin-top: 10px;
  padding: 5px;

  @media (min-width: 992px) {
    padding: 0px 30px;
  }
`;

export const DialogHeader = styled(Typography)`
  font-size: 2rem;
  color: #303030;
  font-weight: 600;

  @media (min-width: 992px) {
    font-size: 0.875rem;
  }
`;

export const DialogDesc = styled(Typography)`
  font-size: 2rem;
  color: #606060;
  font-weight: 400;
  padding: 20px 40px;
  width: 99%;
  margin: 20px 0px;

  @media (min-width: 992px) {
    font-size: 0.875rem;
    margin: 0px;

  }
`;

export const BannerImage = styled('img')`
  width: 100%;
  max-height: 54.313rem;
  cursor: pointer;
`;

export const BannerBox = styled(Box)`
  padding: 1.5rem;
`;

export const CartHeader = styled(Typography)`
  color: #235A91;
  font-weight: 600;
  font-size: 2.2rem;
  width: 92%;
  background: #ECF6FF;
  border-radius: 15px;
  margin: 2rem 2rem;
  padding: 2rem;
`;

export const CartDotIcon = styled(CartDot)`
&.MuiSvgIcon-root {
  width: 3.2rem;
  height: 3.2rem;

  @media(max-width: 991px) {
    width: 6rem;
    height: 5rem;
  }
}
`;

export const RfqBox = styled(Grid)`
  background: #f2f8fd;
  padding: 1rem;
  border-radius: 4px;
  width: 95%;
  margin-left: 1.6rem;
`;

export const RfqBoldInfoText = styled(Typography)`
  font-size: .75rem;
  font-weight: 600;
  color: #464f5b;
`;

export const RfqInfoText = styled(Typography)`
  font-size: .75rem;
  font-weight: 400;
  color: #606060;
`;
